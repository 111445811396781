import React from 'react';
import { Carousel } from 'antd';
import { Tooltip } from 'antd';
import { Link, graphql } from 'gatsby';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Modal, Button, Alert, message, Popconfirm, Result } from 'antd';
import SiteLayout from '@layouts/site-layout';
import Md5 from "crypto-api/src/hasher/md5";
import Hmac from "crypto-api/src/mac/hmac";
import { toHex } from "crypto-api/src/encoder/hex";
import { fromUtf } from "crypto-api/src/encoder/utf";
import Sha256 from "crypto-api/src/hasher/sha256";
import { toBase64 } from "crypto-api/src/encoder/base64";
import urlconfig from "../../config.json";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { InfoCircleOutlined } from '@ant-design/icons';
  


import {
    getEncodedJWTToken,
    // showSuccessMessage,
    // showNoInternetAlert,
} from "../../services/utility";
//   import Payment from '../../components/Payment';
// import api from "../../services/api";
import Razorpay from '../../components/Payment/components/RazorpayWeb';
import Paytm from '../../components/Payment/components/PaytmWeb';
import paytmLogo from './img/paytm.svg';
import razorpayLogo from './img/razorpay.svg';
import ReactPlayer from 'react-player';
import Video2 from '../../components/Video2';


const our_partner = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
};

const axios = require('axios');


export default class InfoPage extends React.Component {
    myRef = null
    myRef1 = null

    state = {
        visible: false,
        loginModalvisible: false,
        loginOtpModalvisible: false,
        stepAppSummaryModalvisible: false,
        stepTabSummaryModalvisible: false,
        gradeValue: '8',
        boardValue: 'cbse',
        validYrsArr: [],
        productType: '',
        validYrValue: '',
        tabSizeValue: '7',
        stepTabPrice: '0',
        stepAppPrice: '0',
        finalPlanId: '',
        stepAppId: '',
        otpHash: '',
        mobile: '',
        countryCode: '',
        // countryCode: '+91',
        userId: '',
        userOtp: '',
        otpError: false,
        plan: {},
        address1: '',
        address2: '',
        pincode: '',
        city: '',
        state: '',
        email: '',
        paymentMode: '',
        gradeAlert: false,
        boardAlert: false,
        validYrValueAlert: false,
        paymentSuccess: false,
        paymentFailure: false,
        defaultValidYrsArr:'',
        default:false,
        phone:''
    };

    showModal = () => {
        this.setState({
            paymentOptionsModalvisible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            paymentOptionsModalvisible: false,
            mobile:'+91',
            phone:'+91',
            userOtp:''
        });
    };

    //   --------------------------------------------------------------

    handleloginModalCancel = () => {
        this.setState({
            loginModalvisible: false,
            mobile:'+91',
            phone:'+91',
            userOtp:'',
            
        });
    }
    handlePaymentCancel = () => {
        this.setState({
            paymentSuccess: false,
            paymentFailure: false,
            mobile:'+91',
            phone:'+91',
            userOtp:''
        });
    }

    handleOtpModalCancel = () => {
        this.setState({
            loginOtpModalvisible: false,
            mobile:'+91',
            phone:'+91',
            userOtp:''
        });
    }

    handlestepAppSummaryCancel = () => {
        this.setState({
            stepAppSummaryModalvisible: false,
            mobile:'+91',
            phone:'+91',
            userOtp:''
        });
    }
    handlestepTabSummaryCancel = () => {
        this.setState({
            stepTabSummaryModalvisible: false,
            mobile:'+91',
            phone:'+91',
            userOtp:''
        });
    }

    showloginModal = (val) => {
        const { gradeValue, boardValue, validYrValue } = this.state
        if (gradeValue == 0 || boardValue === '' || validYrValue === '') {
            this.Loginerror('Please select all the fields')
            this.setState({
                gradeAlert: (gradeValue == 0 ? true : false),
                boardAlert: (boardValue === '' ? true : false),
                validYrValueAlert: (validYrValue === '' ? true : false)
            });
            window.scrollTo(0, this.myRef.offsetTop)
        } else {
            this.setState({
                loginModalvisible: true,
                productType: val,
            });
        }
    }
    Loginerror = (errMsg) => {
        message.warning(errMsg);
    };

    getOtp = async () => {
        
        if (this.state.phone === ''|| this.state.phone === '+91' ) {
            this.Loginerror('Please enter mobile number')
        } else {
            await this.handleRawMobile();

            message.success('Otp sent successfully');
            const inputData = {
                user_mobile: this.state.mobile,
                // user_mobile: `${this.state.mobile}`,
                country_code: this.state.countryCode,
                native_app_call: "true",
            };
            const headers = {
                "Content-Type": "application/json",
            };
            let url1 = 'https://smsapi.stepapp.in/send/otp-to-verify-mobile';
            let res = await axios.post(url1,
                inputData, {
                headers: headers
            })
            this.setState({
                loginOtpModalvisible: true,
                loginModalvisible: false,
                otpHash: res.data.body,
            });
        }
    }

    resendOtp = () => {
        this.getOtp();
    }

    verifyOtp = () => {
        if (this.state.userOtp === '') {
            this.Loginerror('Please enter the otp')
        } else {
            let HASHKey = "StepAppSecretKey";
            let hasher = new Md5();
            let hmac = new Hmac(fromUtf(HASHKey), hasher);
            hmac.update(fromUtf("'" + this.state.userOtp + "'"));
            let hmacresult = toHex(hmac.finalize());
            if (hmacresult === this.state.otpHash) {
                this.getUserDetails();
                return true;
            } else {
                this.setState({
                    otpError: true,

                })
                return false;
            }
        }
    }

    getUserDetails = async (e) => {
        const inputData = {
            user_mobile: this.state.mobile,
            // user_mobile: `+${this.state.mobile}`,
            country_code: this.state.countryCode,
            school_id: "1",
        };
        let authToken = getEncodedJWTToken(inputData);
        const headers = {
            "Content-Type": "application/json",
            Authorization: authToken,
        };
        let url1 = 'https://api.stepapp.in/user';
        let urlFinal = this.getUrlWithToken(url1)
        let userDetail = await axios.post(urlFinal,
            inputData, {
            headers: headers
        })
        let userId = userDetail.data.id;
        localStorage.setItem('userDetail', JSON.stringify(userDetail.data));
        this.setState({
            loginOtpModalvisible: false,
            stepAppSummaryModalvisible: this.state.productType === "stepapp" ? true : false,
            stepTabSummaryModalvisible: this.state.productType === "steptab" ? true : false,
            userId: userId,
        })
    }

    getUrlWithToken = (url) => {
        let arrParamUrl = url.split(urlconfig.apiEndPoint);
        if (arrParamUrl && arrParamUrl.length > 0) {
            let urlWithToken = this.getVerifyParam(arrParamUrl[1]);
            return url + urlWithToken;
        }
    }

    getVerifyParam = (queryString) => {
        let HASHKey = urlconfig.tokenHmacKey;
        let hasher = new Sha256();
        let hmac = new Hmac(fromUtf(HASHKey), hasher);
        let timestamp = Math.round(new Date().getTime() / 1000); //1591927981; //
        let separator = `${queryString.includes("?") ? "&" : "?"}verify`;
        hmac.update(fromUtf(queryString + timestamp));
        let hmacresult = toBase64(hmac.finalize());
        let updatedParam = `${separator}=${timestamp}-${encodeURIComponent(
            hmacresult
        )}`;
        return updatedParam;
    }

    handleGradeChange = async (e) => {
        let selectGrade = e.target.value;
        this.setState({
            gradeValue: selectGrade,
            gradeAlert: false,
            validYrsArr: [],
            validYrValue: '',
            boardValue: '',
            stepAppPrice: '0',
            stepTabPrice: '0',
            default:false

        })
    }

    handleBoardChange = async (e) => {

        let selboardValue = e.target.value
        // let devUrl = 'https://dev-payment.stepapp.in/api/subscription-web-plans'
        let liveUrl = 'https://payments.stepapp.in/api/subscription-web-plans'     

        if (this.state.gradeValue != 0) {
            let validGradeYr = await axios.get(liveUrl, {
                params: {
                    board: selboardValue,
                    grade: this.state.gradeValue,
                }
            })

            if (validGradeYr.data.plans != undefined) {
                let resp = validGradeYr.data.plans[0];
                const propertyNames = Object.keys(validGradeYr.data.plans[0]);
                propertyNames.sort((a, b) => {
                    return parseInt(a.split('-').pop()) - parseInt(b.split('-').pop());
                });
                // let sortedpropertyNames = propertyNames.map(a => { return  `${a}  (${a.split('-').pop() - a[0]} years)`})
                // let sortedpropertyNames = propertyNames.map(a => { return  `${a}  (${a.split('-').pop() - propertyNames[0]} years)`})
                let sortedpropertyNames = propertyNames.map(a => {

                    if(a.split('-').pop() - propertyNames[0] !== 0) 
                {  return (`Grade ${a}  (${a.split('-').pop() - propertyNames[0] +1} years)`) 
               } else{ 
                 return `Grade ${a} (1 year)`}
                } )
                console.log(propertyNames);
                console.log(sortedpropertyNames);
                this.setState({
                    boardValue: selboardValue,
                    availableGradeplans: validGradeYr.data.plans[0],
                    // validYrsArr: propertyNames,
                    validYrsArr: sortedpropertyNames,
                    boardAlert: false,
                    default:false
                })
            } else {
                this.setState({
                    boardValue: selboardValue,
                    availableGradeplans: '',
                    validYrsArr: [],
                    gradeValue: 0,
                    boardAlert: false,
                    default:false
               })
            }
        } else {
            this.Loginerror('Please select grade first')
        }
    }

    handleValidYrChange = (e) => {
        let validYrvals = e.target.value;
        console.log(validYrvals);
        console.log(validYrvals.substring(5));
        console.log(validYrvals.split(' '));

        // let validYrval = validYrvals.split(' ').shift()
        let validYrval = validYrvals.split(' ')[1]
        console.log(validYrval);
        
        try {
            let SevenincTabprice = this.state.availableGradeplans[validYrval][1]['amount'];
            let TenincTabprice = this.state.availableGradeplans[validYrval][2] !== undefined ? this.state.availableGradeplans[validYrval][2]['amount'] : 0;
            let planId7inch = this.state.availableGradeplans[validYrval][1]['id'];
            let planId10inch = this.state.availableGradeplans[validYrval][2] !== undefined ? this.state.availableGradeplans[validYrval][2]['id'] : 0;
            let stepAppPrice = this.state.availableGradeplans[validYrval][3]['amount'];
            let stepAppId = this.state.availableGradeplans[validYrval][3]['id'];
            let stepTabPrice;
            let finalPlanId;

            let tabSizeValue = this.state.tabSizeValue
            if (tabSizeValue === "7") {
                stepTabPrice = SevenincTabprice;
                finalPlanId = planId7inch;
            } else {
                stepTabPrice = TenincTabprice;
                finalPlanId = planId10inch;
            }
            let planObj = {
                id: finalPlanId,
                amount: stepTabPrice
            }
            this.setState({
                // validYrValue: validYrval,
                validYrValue: validYrvals,
                stepTabPrice: stepTabPrice,
                finalPlanId: finalPlanId,
                SevenincTabprice: SevenincTabprice,
                TenincTabprice: TenincTabprice,
                planId7inch: planId7inch,
                planId10inch: planId10inch,
                plan: planObj,
                validYrValueAlert: false,
                stepAppPrice: stepAppPrice,
                stepAppId: stepAppId,
                default:false
            })
        }
        catch (e) {
            console.log(e);
        }
    }

    handleTabTypeChange = (e) => {
        let selTabSize = e.target.value
        let stepTabPrice;
        let stepTabPlanId;
        let tabSizeValue = selTabSize
        if (tabSizeValue === "7") {
            stepTabPrice = this.state.SevenincTabprice
            stepTabPlanId = this.state.planId7inch   
        } else {
            stepTabPrice = this.state.TenincTabprice
            stepTabPlanId = this.state.planId10inch
        }

        let planObj = {
            id: stepTabPlanId,
            amount: stepTabPrice
        }

        if (this.state.TenincTabprice !== 0) {
            this.setState({
                tabSizeValue: selTabSize,
                stepTabPrice: stepTabPrice,
                finalPlanId: stepTabPlanId,
                default:false,
                plan: planObj,
            })
        } else {
            message.warning("No plan available for 10inch Tab");
            this.setState({
                tabSizeValue: "7",
                default: false,
                stepTabPrice: stepTabPrice,
                plan: planObj,
            })
        }

    }
    // handlePinCodeChange = async (e)=>{
    //     console.log(e.target.value);
    //     let finalValue = e.target.value
    //     let value = await axios.get(`https://api.postalpincode.in/pincode/${finalValue}`)
    //     console.log(value.data);
    //     // if(value.data.PostOffice[0] !== null ||value.data.PostOffice[0] !== undefined ){
    //         console.log(value.data[0].PostOffice[0].State);
    //         let val = value.data[0].PostOffice[0].State
    //         this.setState({
    //             pincode:finalValue,
    //             // state: val !== undefined || val !== null ? val:''
    //             state:val
                
    //         })
    //     // }

    // }
    handleFromUpdate = (e) => {
        var pattern = new RegExp(/^[0-9\b]+$/);
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
        e.target.setCustomValidity("")

    }
    handlePhoneChange = (e,value, data, event, formattedValue) => {
        console.log(this.state.mobile);

        console.log(value)
        console.log(value.dialCode);
        console.log(data)
        console.log(formattedValue);
        
    
        let countrycode = `+${value.dialCode}`
        let countryCodeLength = (value.dialCode.length)
        // let a = e.slice(value.dialCode.length)
        // let a = e.shift(value.dialCode)
        // console.log(a);
        console.log(e);
        this.setState({
           mobile: `+${e}`,
           countryCode:countrycode,
           phone:e
        });
        console.log(this.state.mobile);


    }
    handleRawMobile = () => {
         let {countryCode,mobile}= this.state;
         console.log(mobile);
         if(mobile.includes("+")){
            let mobileNumber = mobile.split('+').pop()
         
            let a = countryCode.split('+').pop()
            console.log(a);
            
            let rawPhoneNumber = mobileNumber.slice(a.length)
            console.log(rawPhoneNumber);
            
            this.setState({
                mobile: rawPhoneNumber,
             });           
              console.log(this.state.mobile);
             
         }         
         

    }

    handleCountryCodeChange = (e) => {
        let countryCode = e.target.value;
        this.setState({
            countryCode: countryCode,
        })
    }

    proceedToPayment = (e) => {
        e.preventDefault()

        const { address1, address2, city, pincode, state, mobile, email } = this.state
        if (address1 === '' || address2 === '' || city === "" || pincode === "" || state === '' || mobile === '' || email === '') {
            this.Loginerror('Please enter all the fields')

        } else {
            this.setState({
                stepTabSummaryModalvisible: false,
                paymentOptionsModalvisible: true,
                // loginModalvisible:false
            })
        }
    }

    proceedToPaymentStepapp = (e) => {
        e.preventDefault()

        const { address1, address2, city, pincode, state, mobile, email } = this.state
        let planObj = {
            id: this.state.stepAppId,
            amount: this.state.stepAppPrice
        }
        if (email === '') {
            this.Loginerror('Please enter all the fields')

        } else {
            this.setState({
                plan: planObj,
                stepAppSummaryModalvisible: false,
                paymentOptionsModalvisible: true,
                finalPlanId: this.state.stepAppId,
                // loginModalvisible:false
            })
        }
    }


    // ===========================  PAYMENT ===========================

    // setPaymentMode = (paymentMode = 'paytm') => {
    setPaymentMode = (paymentMode) => {
        this.setState({ paymentMode }, () => {
            console.log(this.state.paymentMode)
        });
    }

    // getOrder = async (mode = 'paytm') => {
    getOrder = async (mode) => {
        try {
            const payload = {
                "plan_id": this.state.finalPlanId,
                "user_id": this.state.userId,
                "board_name": this.state.boardValue || "CBSE",
                "grade_name": this.state.gradeValue,
                "email": this.state.email || "pranoy669@gmail.com",
                "mobile": this.state.mobile,
                "gateway": mode,
                "ad_id": "fcd2055c-779e-45fb-b1dd-705cf8cd199a",
                "source": this.state.sourceVal!==null ? 'franchise1' : ''
            }

            // let devUrl = "https://dev-payment.stepapp.in/api/create-order"
            let liveUrl = "https://payments.stepapp.in/api/create-order"

            const order = await axios.post(liveUrl, payload, null, {
                baseURL: urlconfig.paymentUrl
            });
            return order?.data.order_details;
        } catch (err) {
            console.log(err.message);
        }
    }

    afterPayment = ({ status, data }) => {
        console.log('paymentStatus', { status, data });

        if (data.status === 'success') {
            // this.scrollToMyRef()
            this.setState({ paymentSuccess: true })
            this.resetState()
            setTimeout(() => {
                this.setState({
                    paymentSuccess: false,
                    paymentFailure: false
                })
            }, 5000)
            // navigate('/thankyou')
            // this.props.refreshUser();
            // this.props.history.go(-3);
        } else if (data.status === 'failure') {
            message.warning(data.message);
            this.setState({
                paymentOptionsModalvisible: false,
            })
            this.resetState()
        }

    }

    resetState = () => {
        this.setState({
            paymentOptionsModalvisible: false,
            mobile: '',
            gradeValue: '0',
            boardValue: '',
            validYrValue: '',
            stepTabPrice: '0',
            stepAppPrice: '0',
            address1: '',
            address2: '',
            pincode: '',
            city: '',
            state: '',
            email: '',
            userOtp: '',
            paymentMode: '',
            phone:''
        })
    }
    // scrollToMyRef = () => window.scrollTo(0, this.myRef.offsetTop)

    



    //   --------------------------------------------------------------


    async componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const sourceVal = query.get('source')
        console.log(sourceVal);
        // let devUrl = 'https://dev-payment.stepapp.in/api/subscription-web-plans'
        let liveUrl = 'https://payments.stepapp.in/api/subscription-web-plans'
        let validGradeYr =  await axios.get(liveUrl, {
            params: {
                board: this.state.boardValue,
                grade: this.state.gradeValue,
                source: sourceVal!==null ? 'franchise1' : ''
            }
        })
        console.log(validGradeYr);
        
       if (validGradeYr.data.plans != undefined) {
           let resp = validGradeYr.data.plans[0];
           const propertyNames = Object.keys(validGradeYr.data.plans[0]);
           propertyNames.sort((a, b) => {
            return parseInt(a.split('-').pop()) - parseInt(b.split('-').pop());
           });
        //    let q = propertyNames.map(a => { return  {name:a, years:a.split('-').pop() - a[0]}})
         let sortedpropertyNames = propertyNames.map(a => {

             if(a.split('-').pop() - propertyNames[0] !== 0) 
         {  return (`Grade ${a}  (${a.split('-').pop() - propertyNames[0]+1} years)`) 
        } else{ 
          return `Grade ${a} (1 year)`}
         } )
           console.log(propertyNames);
           console.log(sortedpropertyNames);
           this.setState({
            boardValue: this.state.boardValue,
            availableGradeplans: validGradeYr.data.plans[0],
            validYrsArr: sortedpropertyNames,
            // validYrsArr: propertyNames,
            boardAlert: false,
            default:true,
            // defaultValidYrsArr : propertyNames[0],
            defaultValidYrsArr : sortedpropertyNames[0]
        })
        let SevenincTabprice = this.state.availableGradeplans[propertyNames[0]][1]['amount']
            let TenincTabprice = this.state.availableGradeplans[propertyNames[0]][2] !== undefined ? this.state.availableGradeplans[propertyNames[0]][2]['amount'] : 0;
            let planId7inch = this.state.availableGradeplans[propertyNames[0]][1]['id'];
            let planId10inch = this.state.availableGradeplans[propertyNames[0]][2] !== undefined ? this.state.availableGradeplans[propertyNames[0]][2]['id'] : 0;
            let stepAppPrice = this.state.availableGradeplans[propertyNames[0]][3]['amount'];
            let stepAppId = this.state.availableGradeplans[propertyNames[0]][3]['id'];
            let stepTabPrice;
            let finalPlanId;
            let tabSizeValue = this.state.tabSizeValue
            if (tabSizeValue === "7") {
                stepTabPrice = SevenincTabprice;
                finalPlanId = planId7inch;
            } else {
                stepTabPrice = TenincTabprice;
                finalPlanId = planId10inch;
            }
            let planObj = {
                id: finalPlanId,
                amount: stepTabPrice
            }
            this.setState({
                // validYrValue: propertyNames[0],
                validYrValue:  sortedpropertyNames[0],
                stepTabPrice: stepTabPrice,
                finalPlanId: finalPlanId,
                SevenincTabprice: SevenincTabprice,
                TenincTabprice: TenincTabprice,
                planId7inch: planId7inch,
                planId10inch: planId10inch,
                plan: planObj,
                validYrValueAlert: false,
                stepAppPrice: stepAppPrice,
                stepAppId: stepAppId,
                default :false,
                sourceVal: sourceVal
            })
        }
    }

 

    render() {
        console.log(this.state.countryCode);

        let panacea
        if (this.state.gradeValue == "11" || this.state.gradeValue == "12") {
            panacea = (<li>
                <h3>Panacea Portal</h3>
                <p>Digital portal which brings your learing ecosystem in one place  
                    {/* <a href="#">  Know More</a> */}
                     </p>
            </li>)
        } else {
            panacea = ""
        }  
        
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
            <SiteLayout title="programmes" description={description} image={img}>

                <div className="top_margin_comn" >
                    <div className="comn_bkgimg home_slider_sec"
                        style={{ background: "url('../img/abt_banner_slide.jpg') no-repeat center center /cover", color: "#fff" }}
                    >
                        <div className="container">
                            <h3>PRODUCTS</h3>
                        </div>
                    </div>
                </div>
                {this.state.paymentSuccess ? <Modal
                    className="payment_header"
                    // title="Payment Status"
                    title={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                    visible={this.state.paymentSuccess}
                    // onOk={this.handleOk}
                    footer={null}
                    onCancel={this.handlePaymentCancel}
                    destroyOnClose={true}
                >
                    <div className="login_popup_sec">

                        <p>Payment Successful</p>
                    </div>
                </Modal>
                    : ''}



                <div className="best_sell_prod" ref={(ref) => this.myRef = ref} >
                    <div className="container">
                        <div className="row">
                            <div className="prod_left">
                                <h3>Now you can learn while you play,</h3>
                                <h3>anywhere, anytime</h3>
                            </div>
                            <div className="prod_right">
                                <p>Need help in deciding what's best for your child? Call us at 18002665007 to connect with our consultant. </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {
                     this.state.paymentSuccess ? 
                     <Result
                        status="success"
                        title=" Payment Successfully "
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                      />
              : '' }
                      */}


                <div className="prod_drop" >
                    <div className="container">
                        <div className="row" >
                            <div className="col-md-4" >
                                {this.state.gradeAlert ? <Alert message="Please select a Grade" type="warning" showIcon closable /> : ''
                                }

                                <div className="drop_box">
                                    <label> Product for </label>
                                    <select class="custom-select" name="gradeValue" value={this.state.gradeValue} onChange={this.handleGradeChange}>
                                        <option value="0">Grade</option>
                                        <option value="6">Grade 6</option>
                                        <option value="7">Grade 7</option>
                                        <option value="8">Grade 8</option>
                                        <option value="9">Grade 9</option>
                                        <option value="10">Grade 10</option>
                                        <option value="11">Grade 11</option>
                                        <option value="12">Grade 12</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {this.state.boardAlert ? <Alert message="Please select a Board" type="warning" showIcon closable /> : ''
                                }
                                <div className="drop_box">
                                    <label> Board </label>
                                    <select class="custom-select" name="boardValue" value={this.state.boardValue} onChange={this.handleBoardChange}>
                                        <option value="">Select Board</option>
                                        {/* <option value="oth">Others</option> */}
                                        <option value="icse">ICSE</option>
                                        <option value="ssc">SSC</option>
                                        <option value="cbse">CBSE</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {this.state.validYrValueAlert ? <Alert message="Please select a Valid  For" type="warning" showIcon closable /> : ''
                                }
                                <div className="drop_box">
                                    <label> Valid for </label>
                                    <select class="custom-select" 
                                    // value={this.state.validYrValue} 
                                    value={this.state.default === true ? this.state.defaultValidYrsArr :this.state.validYrValue}
                                    onChange={this.handleValidYrChange}>
                                        <option value="">Grades</option>
                                        {this.state.validYrsArr.map((object, i) => {
                                            return (
                                                <option value={object} name={i} key={i}>{object}</option>
                                            )
                                        })}
                                        {/* <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="">
                    <div className="container">
                        <div className="tab_main_wrapper">
                            <div className="tab_box">
                                <div className="tab_box_header">
                                    <h3>STEPapp <p>Lite plan <Tooltip title="This plan is active for the respective academic years">
                                         <span className="toolTip"><InfoCircleOutlined /></span>
                                     </Tooltip></p>
                                    </h3> 
                                </div>
                                <ul>
                                    <li>
                                        <h3>STEPapp Concepts</h3>
                                        {/* <p>Adaptive personalised learning in a gamified format <a href="#Concepts"> Know More</a></p> */}
                                        <p>The concepts created in STEPapp are generated and created by the extensive research of 400+ IITians and Doctors, who have combined their knowledge and intelligence in a gamified format for Math & Science</p>

                                    </li>
                                    <li className="blk_li">
                                        <h3>&nbsp;</h3>
                                        <p>&nbsp;</p>
                                    </li>
                                    <li className="blk_li">
                                        <h3>&nbsp;</h3>
                                        <p>&nbsp;</p>
                                    </li>
                                    <li className="blk_li">
                                        <h3>&nbsp;</h3>
                                        <p>&nbsp;</p>
                                    </li> 
                                </ul>
                                <div className="btn_sec_prod">
                                    <p>&#8377; {this.state.stepAppPrice}</p>
                                    <button onClick={() => this.showloginModal('stepapp')}>Buy Now</button>
                                </div>
                            </div>

                            <div className="tab_box">
                                <div className="tab_box_header">
                                    <h3>STEPapp Tab
                                    <select class="custom-select" value={this.state.tabSizeValue} onChange={this.handleTabTypeChange}>
                                            <option value="7">7 inch</option>
                                            <option value="10">10 inch</option>
                                        </select>
                                        <p>Comprehensive plan <Tooltip title="This plan is active for the respective academic years">
                                         <span className="toolTip"><InfoCircleOutlined /></span>
                                     </Tooltip></p>
                                    </h3>
                                </div>
                                <ul>
                                    <li>
                                    <h3>STEPapp Concepts in the Adaptive personalised lee Tab M{this.state.tabSizeValue} tab</h3>
                                        {/* <p>Learn concepts in a gamified way on the game-changing Lenovo Tab 4 <a href="#"> Know More</a> </p> */}
                                        <p>The concepts created in STEPapp are generated and created by the extensive research of 400+ IITians and Doctors, who have combined their knowledge and intelligence in a gamified format for Math & Science</p><br/>

                                        <p>Learn concepts in a gamified way on the game-changing Lenovo Tab 4  </p>

                                    </li>
                                    <li>
                                        <h3>Snail Rush </h3>
                                        {/* <p>Prepare for all major national and Olympiad in a gamified format  <a href="#">  Know More</a> </p> */}
                                        <p>Prepare for all major National and International  Olympiad in a gamified format  </p>

                                    </li>
                                    <li>
                                        <h3><a href="https://iitianspace.com/" >PACE IIT & Medical</a> Study Material <Tooltip title="PACE IIT & Medical is our knowledge partner"> <span className="toolTip"><InfoCircleOutlined /></span> </Tooltip></h3>
                                        {/* <p>Crucial study material for IIT, NEET, and all the major competitive exams, available for ready reference  <a href="#">  Know More</a> </p> */}
                                        <p>Crucial study material for IIT, NEET, and all the major competitive exams, available for ready reference  </p>

                                    </li>
                                    {panacea
                                        // <li>
                                        //     <h3>Panacea Portal</h3>
                                        //     <p>Digital portal which brings your learing ecosystem in one place  <a href="#">  Know More</a> </p>
                                        // </li>
                                    }
                                </ul>
                                <div className="btn_sec_prod">
                                    {/* <p>INR 5,000</p> */}
                                    <p>&#8377; {this.state.stepTabPrice}</p>
                                    <button onClick={() => this.showloginModal('steptab')}>Buy Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {
                     this.state.paymentSuccess ? 
                     <Result
                        status="success"
                        title=" Payment Successfully "
                      />
              : '' }
                      */}

                <div className="what_you_get section-title">
                    <div className="container">
                        <h2>What do you get?</h2>
                        <div className="prodt_box" id="Concepts">
                            <h3>STEPapp Concepts</h3>
                            <ul>
                                <li>Adaptive, personalised Multi-featured learning</li>
                                <li>Adaptive, personalised learning in a gamified format</li>
                                <li>Learning outcomes reported to parents and teachers</li>
                                <li>Students are in control with self-paced learning</li>
                                <li>Augments current pedagogy as a homework and revision app</li>
                                <li>Gamification backed by years of extensive research</li>
                            </ul>
                        </div>

                        <div className="prodt_box">
                            <h3>Snail Rush</h3>
                            <ul>
                                <li>Competitive study application for olympiad preparation</li>
                                <li>Prepare for all major National and International olympiads for grades 6th-12th</li>
                                <li> Gamified format for immersive preparation</li>
                                <li>Developed by <a href="https://iitianspace.com/" >PACE IIT & Medical</a> <Tooltip title="PACE IIT & Medical is our knowledge partner"> <span className="toolTip"><InfoCircleOutlined /></span> </Tooltip>  faculties in association with leading authors</li>
                            </ul>
                        </div>

                        <div className="prodt_box">
                            <h3>PACE Study Material</h3>
                            <ul>
                                <li>Comprehensive study material from <a href="https://iitianspace.com/" >PACE IIT & Medical</a>  <Tooltip title="PACE IIT & Medical is our knowledge partner"> <span className="toolTip"><InfoCircleOutlined /></span> </Tooltip>,  (Crucial study material for IIT, NEET, School exams, Board exams and all the major competitive exams, available for ready reference) leading institute in preparation of competitive exams</li>
                                <li>Previous years’ question papers</li>
                                <li>Question banks for competitive exams like IIT and NEET</li>
                                <li>Reference books for conceptual learning</li>
                                <li>Developed by veteran educators</li>
                            </ul>
                        </div>
                        <div className="prodt_box">
                            <h3>Panacea Portal</h3>
                            <ul>
                                <li>Complete range of digital learning material</li>
                                <li>Ebooks, videos, learning material in one place</li>
                                <li>No more hunting for learning material on the internet</li>
                                <li>Curated by top professors at <a href="https://iitianspace.com/" >PACE IIT & Medical</a> <Tooltip title="PACE IIT & Medical is our knowledge partner"> <span className="toolTip"><InfoCircleOutlined /></span> </Tooltip></li>
                            </ul>
                        </div>
                        <div className="prodt_box">
                            <h3>Tablet</h3>
                             <p>Model : ZA500083IN, Product : Lenovo TB-X605LC , Machine Type :	ZA50, Processor :	Qualcomm Snapdragon 450 (8C, 8x A53 @1.8GHz),Graphics :	Integrated Qualcomm Adreno 506 GPU, Chipset : 	Qualcomm SoC Platform, Memory	: 2GB Soldered, Display :	10.1" FHD (1920x1200) IPS 320nits, Touchscreen : 10-point Multi-touch, Expandable Memory :	MicroSD card (Up to 256GB), Storage : 	16GB eMMC, WLAN + Bluetooth	: 11a/b/g/n/ac, 1x1 + BT4.2, WWAN	: 4G LTE, Optional Ports : 	Micro USB, Card Slot :	Single Nano-SIM & MicroSD Card Holder Tray,  Camera : 	Front 5.0MP / Rear 8.0MP, Microphone :	Mono , Battery : 	Integrated 7000mAh, Power Adapter :	5.2V / 2.0A, Operating System :	Android 9.0  </p>
                        </div>
                    </div>
                </div>

                <div className="prog_tag">
                    <p>Call us at 18002665007 to connect with our educational consultants</p>
                </div>

                <div className="container">
                    <div className="student_list">
                        <div className="row">
                            <div className="col-md-4"><div>20,00,000+</div>Student Engaged</div>
                            <div className="col-md-4"><div>1,500+</div>Schools Reached</div>
                            <div className="col-md-4"><div>20,000+</div>Concepts Mastered</div>
                        </div>
                    </div>
                </div>

                <div className="abut_section">
                    <div className="container">
                        <h4 className="commn_heading">STEPapp is the future of Smart Education</h4>
                        <Video2/>
                        {/* <Carousel {...our_partner} className="row patnar-slide our_team_sec">
                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/y-AxlATegLY' width='100%' height='100%' controls='true' />
                                        <h3>Reactions of students of school run by Chairman ICSE Board after playing STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/oiO41WdfOw8' width='100%' height='100%' controls='true' />
                                        <h3>Reactions of students of Sulochanadevi Singhania School, Mumbai after playing STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/2hx_WOmf-u8' width='100%' height='100%' controls='true' />
                                        <h3>Reactions of students of GD Somani School, Cuff Parade, Mumbai, after playing STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/9JQVgo9beW8' width='100%' height='100%' controls='true' />
                                        <h3>Feedback of Teacher of EMRS Khairi Parsoda on implementation of STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/xwj9LI3USQg' width='100%' height='100%' controls='true' />
                                        <h3>Feedback of Principal of JNV Jalgaon on implementation of STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                    <div className="blog_text_area">
                                        <ReactPlayer className="react_player" url='https://youtu.be/AlZlUA5EsdE' width='100%' height='100%' controls='true' />
                                        <h3>Mr Deepak S Parekh HDFC Bank Chairman</h3>
                                    </div>
                                </div>
                            </div>
                        </Carousel> */}
                    </div>
                </div>

                <div className="login_popup">
                    {/* <Button type="primary" onClick={this.showModal}>
                        Sign Up / Login
                    </Button> */}
                    <Modal
                        className="modal_header"
                        title="Sign Up / Login"
                        visible={this.state.loginModalvisible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handleloginModalCancel}
                    >
                        <div className="login_popup_sec">
                            <div className="popup_input">
                              {/* <select className="" value={this.state.countryCode} onChange={this.handleCountryCodeChange}>
                                    <option value="+91">+91</option>
                                    <option value="+971">+971</option>
                                    <option value="+911">+911</option>
                                </select>
                                <input
                                    // type="text"
                                    type="number"
                                    autocomplete="off"
                                    placeholder="Enter your mobile number"
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={this.handleFromUpdate}
                                    style={{'-webkit-appearance': 'none',
                                        'margin': 0}}
                                    required
                                />  */}

                                    <PhoneInput
                                        country={'in'}
                                        value={this.state.phone}
                                        onChange={this.handlePhoneChange}
                                        // autoFormat={false}
                                        required={true}
                                        placeholder={"Enter phone number "}
                                        enableSearch="true"
                                        // copyNumbersOnly="true" 
                                        />
                            </div>
                            <p>By Proceeding, you agree to
                            <a href="/term"> Terms of Use </a> of STEPapp</p>
                            <button onClick={this.getOtp}>Get OTP</button>
                        </div>
                    </Modal>
                </div>

                <div className="login_popup login_popup_otp">
                    {/* <Button type="primary" onClick={this.showModal}>
                        Sign Up / Login
                    </Button> */}
                    <Modal
                        className="modal_header"
                        title="Verify Your Number"
                        visible={this.state.loginOtpModalvisible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handleOtpModalCancel}
                    >
                        <div className="login_popup_sec">
                            {/* <p>Verify Your Number</p> */}
                            <div className="popup_input">
                            {/* <PhoneInput
                                        country={'in'}
                                        value={this.state.mobile}
                                        // autoFormat={false}
                                        required={true}
                                        // placeholder={"Enter phone number "}
                                        // enableSearch="true"
                                        disabled="true"
                                        // copyNumbersOnly="true"
                                        
                                        /> */}
                                <select className=""  disabled={true}>
                                    <option>{this.state.countryCode}</option>
                                    {/* <option>+91</option>
                                    <option>+971</option>
                                    <option>+911</option> */}
                                </select>
                                <input type="text" value={this.state.mobile} placeholder="Enter your mobile number" disabled={true} />
                            </div>
                            <p>An OTP is send on your number, please enter the OTP to verify your number</p>
                            <div className="resend_otp">Enter OTP
                                 <input  
                                 autocomplete="off" 
                                 type="text" 
                                 required   pattern="[0-9]*" 
                                 maxlength="4"
                                 name="userOtp" 
                                 value={this.state.userOtp} onChange={this.handleFromUpdate} required />
                                {this.state.otpError && <span>Wrong OTP</span>}
                            </div>
                            <div className="disp_button">
                                <button onClick={this.resendOtp}>Resend OTP </button>
                                <button onClick={this.verifyOtp}>Verify </button>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="login_popup login_popup_otp">
                    {/* <Button type="primary" onClick={this.showModal}>
                        Select Board
                    </Button> */}
                    <Modal
                        className="modal_header"
                        title="Product Summary"
                        visible={this.state.stepAppSummaryModalvisible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handlestepAppSummaryCancel}
                    >
                        <div className="login_popup_sec">
                            <div className="proj_summry">
                                <form className="" onSubmit={this.proceedToPaymentStepapp}> 
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Product Type</label>
                                            <input placeholder="Product Type" value={this.state.productType} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Class</label>
                                            <input placeholder="Class" value={this.state.gradeValue} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Valid for</label>
                                            <input placeholder="Valid for" value={this.state.validYrValue} required />
                                        </div>
                                        <div className="col-md-12">
                                            <label>Confirm Mobile Number</label>
                                            <input placeholder="Confirm Mobile Number" value={this.state.mobile} required />
                                        </div>
                                        <div className="col-md-12">
                                            <label>Confirm Email ID</label>
                                            <input 
                                             type="email"   
                                             className="form-control"
                                            placeholder="Confirm Email ID"
                                             name="email" 
                                             required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                              value={this.state.email} 
                                              onChange={this.handleFromUpdate} 
                                              required />
                                        </div>
                                    </div>
                                    <div className="paymt_btn">
                                <strong>&#8377; {this.state.stepAppPrice}</strong>
                                <button >Proceed To Payment</button>
                            </div>
                                </form>
                            </div>
                           
                        </div>
                    </Modal>
                </div>

                <div className="login_popup login_popup_otp">
                    {/* <Button type="primary" onClick={this.showModal}>
                        Select Board 2
                    </Button> */}
                    <Modal
                        className="modal_header"
                        title="Product Summary"
                        visible={this.state.stepTabSummaryModalvisible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handlestepTabSummaryCancel}
                    >
                        <div className="login_popup_sec">
                            <div className="proj_summry">
                                <form onSubmit={this.proceedToPayment} >
                                    <div className="row">
                                        <div className="col-md-8">
                                            <label>Product Type</label>
                                            <input placeholder="Product Type" value={`${this.state.productType} ${this.state.tabSizeValue}-inch tab`} required />
                                        </div>
                                        <div className="col-md-6">
                                        <label>Class</label>
                                            <input placeholder="Class" value={this.state.gradeValue} />
                                        </div>
                                        <div className="col-md-6">
                                        <label>Valid for</label>
                                            <input placeholder="Valid for" value={this.state.validYrValue} required />
                                        </div>
                                        <div className="col-md-12">
                                            <label>Confirm Mobile Number</label>
                                            <input placeholder="Confirm Mobile Number" value={this.state.mobile} required />
                                        </div>
                                        <div className="col-md-12">
                                        <label>Confirm Email ID</label>
                                            <input placeholder="Confirm Email ID" 
                                            name='email' 
                                            required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            value={this.state.email}
                                             onChange={this.handleFromUpdate} 
                                              />
                                        </div>
                                        <div className="col-md-12">
                                            <strong class="mb-2 d-block">Add Address</strong>
                                        </div>
                                        <div className="col-md-12">
                                        <label>Address Line 1</label>
                                            <input placeholder="Address Line 1" name='address1' value={this.state.address1} onChange={this.handleFromUpdate} required />
                                        </div>
                                        <div className="col-md-12">
                                        <label>Address Line 2</label>
                                            <input  placeholder="Address Line 2" name='address2' value={this.state.address2} onChange={this.handleFromUpdate} />
                                        </div>
                                        <div className="form-group col-md-6">
                                         <label>Pincode</label>
                                            <input   
                                            type="text" 
                                            value={this.state.pincode}
                                            onInvalid={e => e.target.setCustomValidity("Enter numbers only")}
                                            className="form-control"
                                            required pattern="[0-9]*"  
                                            placeholder="Pincode" 
                                            name='pincode' 
                                             onChange={this.handleFromUpdate}
                                              />
                                        </div>
                                        <div className="col-md-6">
                                        <label>City</label>
                                        <input   type="text" 
                                            className="form-control" 
                                            required pattern="[A-z ']*"
                                             placeholder="City" name='city'
                                             onChange={this.handleFromUpdate} 
                                             onInvalid={e => e.target.setCustomValidity("Enter letters only")}
                                            required />
                                            
                                        </div>
                                        <div className="col-md-6">
                                        <label>State</label>
                                        <input  type="text" 
                                            className="form-control" 
                                            required pattern="[A-z ']*" placeholder="State"
                                             name='state' value={this.state.state}
                                             onInvalid={e => e.target.setCustomValidity("Enter letters only")}
                                              onChange={this.handleFromUpdate} required />
                                        </div>
                                    </div>
                                    <div className="paymt_btn">
                                <strong>&#8377; {this.state.stepTabPrice}</strong>
                                <button type="submit" >Proceed To Payment</button>
                            </div>
                                </form>
             
                            </div>
                            
                        </div>
                    </Modal>
                </div>


                <div className="login_popup login_popup_otp">
                    {/* <Button type="primary" onClick={this.showModal}>
                        Select Board 2
                    </Button> */}
                    <Modal
                        className="modal_header"
                        title="Payment Options"
                        visible={this.state.paymentOptionsModalvisible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <div className="login_popup_sec">
                            <div className="proj_summry">
                                <div className="col-sm-10  mx-auto  my-auto paymentCard">
                                    {/* <div className="row no-gutters mt-1">
                                        <div className="col-md-12 text-center title mb-4 ">Payment Options</div>
                                    </div> */}
                                    <div className="content border-sepration">
                                        <div className="row my-2 ">
                                            <div className="col-md-12 text-center mx-auto title-sec my-4">Select Payment Method</div>
                                            <div className="col-sm-12 mx-auto">
                                                <div className="form-group ">
                                                    <div className="row  mb-3 d-flex justify-content-center no-gutters">
                                                        {
                                                            ['razorpay', 'paytm'].map(mode => (
                                                                <div className=" payment-item-flex-small text-center"  >
                                                                    <input type="radio" name="radio1" id={mode} value={mode} onChange={() => this.setPaymentMode(mode)} />
                                                                    <label className="select-label-payment" htmlFor={mode} onClick={() => this.setPaymentMode(mode)}>
                                                                        <div className="row no-gutters m-0 p-0">
                                                                            <div className="col-md-12 ">
                                                                                <div className="row no-gutters">
                                                                                    {/* <div className="col-md-12 payment-title pt-2">{mode.toUpperCase()}</div> */}
                                                                                    <div className="col-md-12 text-center pt-1 ">
                                                                                        <img src={mode === 'paytm' ? paytmLogo : razorpayLogo} width="auto" height="200" width="100" className="img-fluid px-2" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                        {/* {
                                                            ['razorpay', 'paytm'].map(mode => (
                                                                <div className=" payment-item-flex-small"  >
                                                                   <input type="image" src={mode === 'paytm' ? paytmLogo : razorpayLogo} onClick={() => this.setPaymentMode(mode)} alt="Submit" width="48" height="48"/>
                                                                    <label className="select-label-payment" htmlFor={mode} onClick={() => this.setPaymentMode(mode)}>
                                                                        <div className="row no-gutters m-0 p-0">
                                                                            <div className="col-md-12 ">
                                                                                <div className="row no-gutters">
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </label>

                                                                   
                                                                </div>
                                                            ))
                                                        } */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 m-auto form-group pb-4">
                                                    {
                                                        this.state.paymentMode === 'paytm' ? (
                                                            <Paytm
                                                                plan={this.state.plan}
                                                                user={this.props.loggedInUser}
                                                                getOrder={this.getOrder}
                                                                action={this.afterPayment}
                                                            />
                                                        ) : (
                                                                <Razorpay
                                                                    plan={this.state.plan}
                                                                    user={this.props.loggedInUser}
                                                                    getOrder={this.getOrder}
                                                                    action={this.afterPayment}
                                                                />
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="paymt_btn">
                                <strong>&#8377; 25000</strong>
                                <button>Proceed To Payment</button>
                            </div> */}
                        </div>
                    </Modal>
                </div>

                <div className="login_popup">
                    <Modal
                        className="modal_header"
                        title="Product Summary"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <div className="login_popup_sec">
                            <div className="radio_board">
                                <form>
                                    <ul>
                                        <li>
                                            <input type="radio" id="board1" name="boardsec" />
                                            <label htmlFor="board1" className="dsdsdsdsd">
                                                <span><img src="img/CBSC.png" alt="" /></span>
                                                <div ><strong>CBSC</strong>Central Board of Secondary Education</div>
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="board2" name="boardsec" />
                                            <label htmlFor="board2">
                                                <span><img src="img/ICSE.png" alt="" /></span>
                                                <div className=""><strong>ICSE</strong>Indian Certificate of Secondary Education</div>
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="board3" name="boardsec" />
                                            <label htmlFor="board3">
                                                <span><img src="img/SSC.png" alt="" /></span>
                                                <div className=""><strong>SSC</strong>Secondary School Certificate</div>
                                            </label>
                                        </li>
                                        <li>
                                            <input type="radio" id="board4" name="boardsec" />
                                            <label htmlFor="board4">
                                                <span><img src="img/others.png" alt="" /></span>
                                                <div className=""><strong>OTHERS</strong>(Any Other Boards)</div>
                                            </label>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>

            </SiteLayout>
        )
    }
}

